// Private Functions
function getOrCreateBarChartLegendList (chart, id) {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.classList.add('BarChart-legend-list');
    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};


/**
 * Creates a custom HTML Legend
 *
 * https://www.chartjs.org/docs/3.9.1/samples/legend/html.html
 * @param {Chart Object} chart
 * @param {*} args
 * @param {Chart Options Object} options
 */
export function handleCreatingLegendAfterUpdate(chart, args, options) {
  const ul = getOrCreateBarChartLegendList(chart, options.containerID);

  // Remove old legend items
  while (ul.firstChild) {
    ul.firstChild.remove();
  }

  // Reuse the built-in legendItems generator
  const items = chart.options.plugins.legend.labels.generateLabels(chart);

  items.forEach(item => {
    const li = document.createElement('li');
    li.classList.add('BarChart-legend-item')
    // li.style.alignItems = 'center';
    // li.style.cursor = 'pointer';
    // li.style.display = 'flex';
    // li.style.flexDirection = 'row';
    // li.style.marginLeft = '10px';

    li.onclick = () => {
      const {type} = chart.config;
      if (type === 'pie' || type === 'doughnut') {
        // Pie and doughnut charts only have a single dataset and visibility is per item
        chart.toggleDataVisibility(item.index);
      } else {
        chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
      }
      chart.update();
    };

    // Color box
    const boxSpan = document.createElement('span');
    boxSpan.innerHTML = `<svg class="icon icon--external-icon icon icon-check" width="10" height="10" aria-hidden="true" focusable="false"><use xlink:href="#svg-check"></use></svg>`;
    boxSpan.classList.add('BarChart-legend-square');

    if (item.hidden) {
      boxSpan.style.background = 'transparent';
      boxSpan.style.border = '2px solid ' + item.fillStyle;
    } else {
      boxSpan.style.background = item.fillStyle;
      boxSpan.style.borderColor = item.strokeStyle;
      boxSpan.style.borderWidth = item.lineWidth + 'px';
    }
    // boxSpan.style.display = 'inline-block';
    // boxSpan.style.height = '20px';
    // boxSpan.style.marginRight = '10px';
    // boxSpan.style.width = '20px';

    // Text
    const textContainer = document.createElement('p');
    textContainer.classList.add('BarChart-legend-label');
    textContainer.style.color = item.fontColor;

    // textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

    const text = document.createTextNode(item.text);
    textContainer.appendChild(text);


    li.appendChild(boxSpan);
    li.appendChild(textContainer);
    ul.appendChild(li);
  })
}
