import ProgressIndicator from './progress-indicator';
import ExpandToggle from "@threespot/expand-toggle";

export default class MobileNavigation {
  constructor(opts) {
    this.menu = opts.menu;
    this.mobileButton = opts.mobileButton;

    if (!this.mobileButton) {
      console.error('Unable to init: opts.mobileButton must be set');
      return;
    }

    if (!this.menu) {
      console.error('Unable to init: opts.menu must be set');
      return;
    }

    this.toggle = new ExpandToggle(this.mobileButton);
    this.progressIndicator = new ProgressIndicator({
      el: this.menu,
    });

    this.menu.addEventListener('click', this.handleClickingLink.bind(this));
  }

  handleClickingLink(evt) {
    let { target } = evt;

    if (target.tagName !== 'A') {
      return false;
    }

    this.toggle.collapse();
  }
}
