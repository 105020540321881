import Chart from 'chart.js/auto'; // FIXME: Make sure you specified the exact modules you are using in production https://www.chartjs.org/docs/latest/getting-started/integration.html#bundle-optimization
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { handleCreatingLegendAfterUpdate } from '../helpers/legend-helpers';

Chart.register(ChartDataLabels);

// Creates a BarChart with Dataset Labels
export default function BarChart(params) {
  let fontFamily =
    '"Work Sans", -apple-system, Segoe UI, sans-serif, color-emoji';
  let legendEl = params.legendEl;


  let defaults = {
    type: 'bar',
    options: {
      indexAxis: 'x',
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        title: false,
        tooltip: {
          enabled: false // Hides the tooltip on hover
        },
        htmlLegend: {
          containerID: legendEl.getAttribute('id')
        },
        legend: {
          display: false
        },
        // Datalabels is from Chart.js Datalabels plugin
        // https://chartjs-plugin-datalabels.netlify.app/guide/options.html
        datalabels: {
          anchor: 'end',
          rotation: 270,
          color: '#003240',
          font: {
            weight: fontFamily,
            size: 22
          },
          align: 'top',
          // Changes the labels value to include a % at the end of it
          formatter: function (value, context) {
            let index = context.dataset.data.indexOf(value);

            if (!value) {
              return null;
            }

            if (!Array.isArray(context.dataset.has_asterisk) || context.dataset.has_asterisk === undefined) {
              return value + '%';
            }

            if (context.dataset.has_asterisk[index]) {
              return value + '%' + '*';
            }

            return value + '%';
          }
        }
      },
      scales: {
        x: {
          ticks: {
            font: {
              family:  "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
              weight: 'bold'
            }
          },
          grid: {
            display: false // Hides vertical line dividing each dataset via the x-axis
          },
          ticks: {
            maxRotation: 50,
            minRotation: 0
          }
        },
        y: {
          ticks: {
            display: false // hides the ticks that display the values along the Y-axis
          }
        }
      },
      layout: {
        padding: {
          left: 60,
          right: 60,
          top: 60,
          bottom: 60
        }
      },
      onResize: (chart, size) => {
        if (window.innerWidth < 700) {
          chart.options.layout.padding.left = 0;
          chart.options.layout.padding.right = 0;
        }

        if (window.innerWidth  < 700 && params.roateLabelOnMobile) {
          chart.options.scales.x.ticks.maxRotation = 90;
          chart.options.scales.x.ticks.minRotation = 90;
        }

        if (window.innerWidth  > 700) {
          chart.options.scales.x.ticks.maxRotation = 50;
          chart.options.scales.x.ticks.minRotation = 0;
        }
      }
    },
    plugins: [
      {
        id: 'htmlLegend', // I'm not sure because it wasn't in the docs but this makes it work (copied from the sample)
        afterUpdate: handleCreatingLegendAfterUpdate
      }
    ]
  };

  return new Chart(params.el, Object.assign(defaults, { data: params.data }));
}
