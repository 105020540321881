export default class ProgressIndicator {
  constructor(opts) {
    this.el = opts.el;
    // this.sectionsEl = opts.sectionsEl;
    this.activeLinks = [];

    this.progressLinks = this.el.querySelectorAll('a');

    let options = {
      root: null, // set to null to make the root the browser's viewport
      rootMargin: '0px',
      threshold: 0.9
    };


    // Closes the progress indicator automatically after a set interval time
    if (opts.closeMenuInterval) {
      setTimeout(() => {
        this.el.classList.remove('is-open');
      }, opts.closeMenuInterval);
    }
  }

  handleIntersection(entries, observer) {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        return;
      }

      let { target } = entry;

      if (!target.id) {
        console.error(`Target does not have an id associated to it for reference. Target: ${target.tagName}.${target.getAttribute('class')}`);
        return;
      }

      let link = this.el.querySelector(`a[href="#${target.id}"]`);

      if (!link) {
        console.error(`Progress indicator link with href: #${target.id} does not exist`);
        this.deactivateLinks();
        return;
      }

      let links = [];
      let parentLink = this.getParent(link);
      let prevSiblingLink = this.getPrevSibling(link);

      links.push(link);

      while(prevSiblingLink) {
        links.push(prevSiblingLink);
        prevSiblingLink = this.getPrevSibling(prevSiblingLink);
      }

      while (parentLink) {
        links.push(parentLink);
        parentLink = this.getParent(parentLink);
      }


      this.deactivateLinks();
      this.activateLinks(links);
    });
  }

  /**
   * Retrieves the parent element of the progress indicicator nav link.
   *
   * @param {HTMLElement} link
   * @returns {HTMLElement} | null
   */
  getParent(link) {
    let list = link.closest('ul');

    if (!list) {
      return null;
    }

    let parent = list.previousElementSibling;

    if (!parent) {
      return null;
    }

    if (parent.tagName !== 'A') {
      // console.error(`Parent Element: ${parent.tagName} - Parent element must be an anchor tag`);
      return null;
    }

    return parent;
  }

  /**
   * Retrieves the previous sibling of a link
   * @param {*} link
   */
  getPrevSibling(link) {
    let listItem = link.closest('li.ProgressIndicator-sublist-item');

    if (!listItem) {
      return null;
    }

    let sibling = listItem.previousElementSibling;

    if (!sibling) {
      return null;
    }

    return sibling.querySelector('a');
  }

  /**
   * Remove the active class from the activeLinks and clear the activeLinks
   *
   * @returns void
   */
  deactivateLinks() {
    while (this.activeLinks.length > 0) {
      let activeLink = this.activeLinks.pop();

      activeLink.classList.remove('is-active');
    }

    return;
  }

  /**
   * Add the links to the activeLinks property and add the active class to the links
   *
   * @param {HTMLElement[]} links
   * @return boolean
   */
  activateLinks(links) {
    if (!Array.isArray(links)) {
      console.error('Links must be an array')
      return false;
    }

    links.forEach(link => {
      link.classList.add('is-active');
      this.activeLinks.push(link);
    });

    return true;
  }
}
