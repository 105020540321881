// Copies the text storied inside of the element's data-link attribute to the clipboard
// This only happens when the user clicks on the element
export default class CopyLink {
  constructor(el) {
    this.el = el;
    this.ariaLabel = el.getAttribute('aria-label');
    this.toolTip = el.querySelector('[data-tooltip]');
    this.el.addEventListener('click', this.handleClickingBtn.bind(this));
  }

  handleClickingBtn(evt) {
    evt.preventDefault();

    let url = this.el.getAttribute('data-link');

    if (!url) {
      console.error("Please provide a value in CopyLink's data-link attribute");
      return false;
    }

    navigator.clipboard.writeText(url).then(() => {
      this.el.setAttribute('aria-label', 'Copied!');
      this.toolTip.classList.add('show');

      setTimeout(() => {
        this.el.setAttribute('aria-label', this.ariaLabel);
        this.toolTip.classList.remove('show');
      }, 2000);
      console.log(`Successfully copied ${url}`);
    }, (err) => {
      console.log(err);
    });
  }
}
