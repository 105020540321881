import ProgressIndicator from './components/progress-indicator';
import MobileNavigation from './components/mobile-navigation';
// import NextPrev from './components/next-prev';
import ExpandToggle from '@threespot/expand-toggle';
import Tabs from './components/tabs';
import CopyLink from './components/copy-link';
import BarChart from './components/bar-chart';
import Chart from 'chart.js/auto';

// let nextPreviousButtons = new NextPrev({
//   prevBtn: document.querySelector('button[data-target="prev"'),
//   nextBtn: document.querySelector('button[data-target="next"]')
// });

// Desktop Progress Indicator
let progressIndicator = new ProgressIndicator({
  el: document.querySelector('.ProgressIndicator'),
  // sectionsEl: document.querySelectorAll('.Section'),
  closeMenuInterval: 2000
});


let mobileNav = new MobileNavigation({
  mobileButton: document.querySelector('[data-expands="mobile-menu"]'),
  menu: document.querySelector('.MobileNav-menu'),
  // sections: document.querySelectorAll('.Section')
});


let sections = document.querySelectorAll('[data-section]');

let options = {
  root: null, // set to null to make the root the browser's viewport
  rootMargin: '0px',
  threshold: .9
};

let observer = new IntersectionObserver((entries, observer) => {
  progressIndicator.handleIntersection(entries, observer);
  mobileNav.progressIndicator.handleIntersection(entries, observer);


}, options);

sections.forEach(section => {
  observer.observe(section);
});

let textExpansions = document.querySelectorAll('[data-component="text-expansion-toggle"]');
textExpansions.forEach(textExpansion => new ExpandToggle(textExpansion));

document.querySelectorAll('[data-tabs]').forEach(tab => {
  new Tabs(tab);
});

document.querySelectorAll('[data-copyLink]').forEach(link => {
  new CopyLink(link);
});


// Doughnut Chart
document.querySelectorAll('[data-doughnut]').forEach(el => {
  let labels = JSON.parse(el.getAttribute('data-chart-label'));
  let data = JSON.parse(el.getAttribute('data-chart-data'));
  let title = el.getAttribute('data-chart-title');
  let config = {
    type: 'doughnut',
    data: {},
    options: {
      responsive: true,
      borderColor: 'transparent',
      plugins: {
        datalabels: false,
        tooltip:  {
          enabled: false
        },
        legend: {
          display: false
        },
        title: {
          display: false
        }
      }
    }
  };

  if (!el) {
    console.error(`No canvas[data-chart] inside of div#${el.getAttribute('id')} container.`);
    return null;
  }

  config.data.labels = labels.map(label => label.title );

  // https://www.chartjs.org/docs/3.9.1/charts/doughnut.html
  config.data.datasets = [{
    label: title,
    data: data.map(num => parseFloat(num)),
    backgroundColor: labels.map(label => label.color)
  }];

  new Chart(el, config);
});

// Create Bar Chart if an element with [data-bar-chart] attribute is detected
document.querySelectorAll('[data-bar-chart]').forEach(el => {
  let chartContainer = el.querySelector('canvas');
  let params = {};
  let datasets = JSON.parse(chartContainer.getAttribute('data-chart-datasets'));
  let labels = JSON.parse(chartContainer.getAttribute('data-chart-labels'));
  let roateLabelOnMobile = el.getAttribute('data-chart-mobile-rotate');


  // Ensure that datasets[index].data array are float values
  datasets.forEach((dataset, index) => {
    datasets[index].data = dataset.data.map(val => parseFloat(val));
  });

  params.data = {
    datasets,
    labels
  }

  params.el = chartContainer;
  params.legendEl = el.querySelector('[data-chart-legend]');
  params.roateLabelOnMobile = roateLabelOnMobile === 'true' ? true : false;

  BarChart(params);

});
